import { IntlShape } from "react-intl"
import { AllEntryDatumTypes, VatRate } from "./inputMask"

export type CurrencyCode =
  | "USD"
  | "GBP"
  | "DKK"
  | "JPY"
  | "SEK"
  | "CHF"
  | "NOK"
  | "BGN"
  | "CZK"
  | "HUF"
  | "PLN"
  | "RON"
  | "TRY"
  | "AUD"
  | "CAD"
  | "HKD"
  | "NZD"
  | "SGD"
  | "KRW"
  | "ZAR"
  | "CNY"
  | "RUB"
  | "IDR"
  | "HRK"
  | "BRL"
  | "INR"
  | "ILS"
  | "EUR"
  | "XOF"

export const formatEntryDatumType = ({
  entryType,
  vatRate,
  intl,
}: {
  entryType: AllEntryDatumTypes
  vatRate: VatRate
  intl: IntlShape
}) => {
  switch (entryType) {
    case "tax_included":
      return intl.formatMessage({ id: "input-mask.entry-data.tax_included" })
    case "tax_excluded":
      return intl.formatMessage({ id: "input-mask.entry-data.tax_excluded" })
    case "vat":
      return `${intl.formatMessage({
        id: "input-mask.entry-data.vat",
      })} ${vatRate}%`
    case "eu_vat":
    case "eu_reverse_vat":
      return intl.formatMessage({ id: "input-mask.entry-data.eu_vat" })
    case "eu_goods_vat":
    case "eu_goods_reverse_vat":
      return intl.formatMessage({ id: "input-mask.entry-data.eu_goods_vat" })
    case "world_vat":
    case "world_reverse_vat":
      return intl.formatMessage({ id: "input-mask.entry-data.world_vat" })
    case "world_goods_vat":
    case "world_goods_reverse_vat":
      return intl.formatMessage({ id: "input-mask.entry-data.world_goods_vat" })
    case "deposit":
      return intl.formatMessage({ id: "input-mask.entry-data.deposit" })
    case "reverse_deposit":
      return intl.formatMessage({ id: "input-mask.entry-data.reverse_deposit" })
    case "construction_vat":
    case "construction_reverse_vat":
      return intl.formatMessage({
        id: "input-mask.entry-data.construction_vat",
      })
    default:
      return intl.formatMessage({
        id: `input-mask.entry-data.${entryType}`,
      })
  }
}

export const formattedCurrencySymbol = (currency: CurrencyCode) => {
  switch (currency) {
    case "USD":
      return "$"
    case "EUR":
      return "€"
    case "GBP":
      return "£"
    case "DKK":
      return "kr"
    case "JPY":
      return "¥"
    case "SEK":
      return "kr"
    case "CHF":
      return "chf"
    case "NOK":
      return "kr"
    case "BGN":
      return "лв"
    case "CZK":
      return "czk"
    case "HUF":
      return "Ft"
    case "PLN":
      return "zł"
    case "RON":
      return "L"
    case "TRY":
      return "₺"
    case "AUD":
      return "$"
    case "CAD":
      return "$"
    case "HKD":
      return "HK$"
    case "NZD":
      return "$"
    case "SGD":
      return "S$"
    case "KRW":
      return "₩"
    case "ZAR":
      return "R"
    case "CNY":
      return "¥"
    case "RUB":
      return "₽"
    case "IDR":
      return "Rp"
    case "HRK":
      return "kn"
    case "BRL":
      return "R$"
    case "INR":
      return "₹"
    case "ILS":
      return "₪"
    case "XOF":
      return "XOF"
    default:
      return "€"
  }
}

export const addZeroAfterComma = (amount: string) => {
  const amountArray = amount.split(".")
  if (amountArray[1] === "0") return amountArray[0]
  const amountString = amountArray.join(",")
  return amountArray[1].length === 1 ? `${amount}0` : amountString
}
